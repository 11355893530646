<template>
  <equalizer
    :classes="['Card', 'MenuByline']">
    <grid-row
      :keepColumnWidth="true"
      class="MenuCardList">
      <grid-column
        v-if="menuCards.length % 2 !== 0"
        class="MenuPrefaceCard Card">
        <div class="MenuPrefaceCardTitle">
          {{ $t('menuPrefaceCard.menuPrefaceCardTitle') }}
        </div>
        <div class="MenuPrefaceCardByline">
          {{ $t('menuPrefaceCard.menuPrefaceCardByline') }}
        </div>
      </grid-column>
      <grid-column
        v-for="(menuCardDetails, index) in menuCards"
        :key="`menu-card-${index}`">
        <menu-card
          :menuCardDetails="menuCardDetails"
          @contact="contact"/>
      </grid-column>
    </grid-row>
  </equalizer>
</template>

<script>
import equalizer from 'vue-equalizer'
import GridColumn from '@/components/common/GridColumn'
import GridRow from '@/components/common/GridRow'
import MenuCard from './MenuCard'

export default {
  name: 'menu-card-list',
  components: {
    equalizer,
    GridColumn,
    GridRow,
    MenuCard
  },
  props: {
    menuCards: {
      type: Array,
      required: true
    }
  },
  methods: {
    contact() {
      this.$emit('contact')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
@import "~@/assets/css/_shared_variables.sass";

.MenuCardList {
  text-align: center;

  .MenuPrefaceCard {
    padding: $space-m;
    color: $white;
    text-align: left;
    background-image: url('~@/assets/images/menu-preface-card.jpg');
    min-height: 425px;

    @media only screen and (max-width: 960px) {
      display: none;
    }

    .MenuPrefaceCardTitle {
      margin-bottom: $space-xs;
      @extend %display_large;
    }

    .MenuPrefaceCardByline {
      margin-bottom: $space-l;
      @extend %display_light;
    }
  }

  /deep/ > .GridColumn {
    max-width: calc(100% / 2 - #{$space-xs}) !important;

    &:nth-of-type(odd):not(.MenuPrefaceCard) {
      padding-left: 0;
      padding-right: $space-xs;
    }

    &:nth-of-type(even):not(.MenuPrefaceCard) {
      padding-left: $space-xs;
      padding-right: 0;
    }

    &.MenuPrefaceCard {
      margin-right: $space-xs;
      max-width: calc(100% / 2 - #{$space-m}) !important;
    }

    @media only screen and (max-width: 960px) {
      width: 50% !important;
      max-width: 50% !important;

      &:nth-of-type(odd):not(.MenuPrefaceCard) {
        padding-left: $space-xs;
        padding-right: $space-xs;
      }

      &:nth-of-type(even):not(.MenuPrefaceCard) {
        padding-left: $space-xs;
        padding-right: $space-xs;
      }
    }

    @media only screen and (max-width: 640px) {
      width: 100% !important;
      max-width: 100% !important;
    }
  }
}
</style>
