<template>
  <base-page
    shoutType="catering"
    :class="{
      CateringPage: true,
      VideoPaused: !videoPlayed
    }">
    <template slot="header">
      <page-header
        :isBannerVideo="isBannerVideo"
        :bannerSrc="bannerSrc"
        bannerClass="HomeBanner"
        :bannerMinHeight="500">
        <banner-content
          theme="dark"
          :title="bannerTitle">
          <template slot="byline">
            <span v-html="bannerByline"/>
            <span
              class="HeaderCta Button--text"
              @click="contact"
              v-html="bannerCta"/>
          </template>
        </banner-content>
      </page-header>
    </template>

    <div class="BodyContent Container">
      <div
        v-if="menuFilterVisible"
        class="Section">
        <menu-filter
          @contact="contact"
          @search="search"/>
      </div>
      <div class="Section MenuCardListSection">
        <div
          v-if="sundayAnnouncementBannerVisible"
          class="Announcement">
          <div
            class="AnnouncementTitle"
            v-html="$t('announcement.title')"/>
          <div
            class="AnnouncementBody"
            v-html="$t('announcement.body')"/>
        </div>
        <div
          v-if="festiveSurchargeNoticeVisible"
          class="festive-surcharge-notice">
          {{ $t('home.festiveSurchargeNotice') }}
        </div>
        <highlight-menus
          v-if="cnyMenus.length > 0"
          :menus="cnyMenus"
          type="cny"
          @selectMenu="selectMenu"/>
        <highlight-menus
          v-if="christmasMenus.length > 0"
          :menus="christmasMenus"
          type="christmas"
          @selectMenu="selectMenu"/>
        <custom-menu
          v-if="introMenu"
          :menu="introMenu"
          @selectMenu="selectMenu"/>
        <highlight-menus
          v-if="nationalDayMenus.length > 0"
          :menus="nationalDayMenus"
          type="national"
          @selectMenu="selectMenu"/>
        <highlight-menus
          v-if="standardBuffetMenus.length > 0"
          :menus="standardBuffetMenus"
          type="standard"
          @selectMenu="selectMenu"/>
        <highlight-menus
          v-if="bentoMenus.length > 0"
          :menus="bentoMenus"
          type="bento"
          @selectMenu="selectMenu"/>
        <highlight-menus
          v-if="miniBuffetMenus.length > 0"
          type="mini"
          :menus="miniBuffetMenus"
          @selectMenu="selectMenu"/>
        <highlight-menus
          v-if="partySetMenus.length > 0"
          :menus="partySetMenus"
          @selectMenu="selectMenu"/>

        <div
          v-if="loadingMenuCards"
          class="MenusLoading">
          {{ $t('catering.menusLoading') }}
        </div>
        <highlight-menus
          v-if="healthyMenus.length > 0"
          :menus="healthyMenus"
          type="healthy"
          @selectMenu="selectMenu"/>
        <highlight-ala-carte-menu
          v-if="hasAlaCarteMenu && beforeFestiveAlaCarteEndDate && highlightMenus.length > 0"
          :menus="highlightMenus"
          @selectMenu="selectMenu"/>
        <h3
          v-if="filtersPresent && filteredMenuCards.length > 0"
          class="FilteredMenuHeader">
          {{ $t('catering.filteredMenuHeader') }}
        </h3>
        <menu-card-list
          v-if="filteredMenuCards.length > 0"
          :menuCards="filteredMenuCards"
          @contact="contact"/>
        <div class="WeddingBanner">
          <div class="WeddingContent">
            <h2 class="WeddingTitle">
              <div>{{ $t('weddingBanner.title.weddings') }}</div>
              <div class="TitleBy">
                {{ $t('weddingBanner.title.by') }}
              </div>
              <div>{{ $t('weddingBanner.title.grain') }}</div>
            </h2>
            <div
              class="WeddingByline"
              v-html="$t('weddingBanner.byline', { displayClass: 'Display' })"/>
            <a
              href="https://occasions.grain.com.sg/weddings"
              class="btn btn-primary">
              {{ $t('weddingBanner.actionLabel') }}
            </a>
          </div>
        </div>
        <h3
          v-if="filtersPresent && filteredMenuCards.length > 0 && filteredOutMenuCards.length > 0"
          class="FilteredMenuHeader">
          {{ $t('catering.filteredOutMenuHeader') }}
        </h3>
        <menu-card-list
          v-if="filteredOutMenuCards.length > 0"
          :menuCards="filteredOutMenuCards"
          @selectMenu="selectMenu"/>
      </div>
    </div>

    <gallery-banner/>

    <div class="BodyContent Container">
      <div class="Section CustomersSection">
        <customers-image-list/>
      </div>

      <div
        id="contact"
        class="Section EnquirySection">
        <base-header :title="$t('catering.enquirySectionTitle')">
          <span
            slot="byline"
            v-html="$t('catering.enquirySectionByline')"/>
        </base-header>
        <enquiry-form/>
        <base-header>
          <div
            slot="byline"
            class="AfterEnquiryForm"
            v-html="
              $t('catering.afterEnquiryForm', {
                phoneLink: `<a class='Button--text' href='tel:+6531635336'>+65 3163 5336</a>`,
                emailLink: `<a class='Button--text' href='mailto:catering@grain.com.sg'>catering@grain.com.sg</a>`
              })
            "/>
        </base-header>
      </div>
    </div>
    <div
      v-if="getIframeMenu"
      class="menu-iframe">
      <div
        class="modal-overlay"
        @click="onClearIframeMenu"/>
      <div class="frame-container">
        <iframe :src="getIframeMenu.pdf"/>
        <div
          class="close-button"
          @click="onClearIframeMenu">
          ×
        </div>
      </div>
    </div>
    <template slot="footer">
      <page-footer/>
    </template>
  </base-page>
</template>

<script>
import {
  CNY_END_DATE,
  FESTIVE_SURCHARGE_END_DATE,
  filterBentoMenus,
  filterChristmasMenus,
  filterCnyMenus,
  filterMiniBuffetMenus,
  filterNationalDayMenus,
  filterPartySetMenus,
  filterStandardBuffetMenus
} from '@/assets/js/highlight-menu-helper'
import { MENU_IDS, SUNDAY_ANNOUNCEMENT_END_DATE, WEDDING_MENU_IDS, isMenuVisible } from '@/assets/js/order-helper'
import { RESET_ORDER_STATE, SET_CHECKOUT_ERRORS_VISIBLE, SET_MENU_COVER, SET_MENU_ID } from '../actions'
import BannerContent from '@/components/BannerContent'
import BaseHeader from '@/components/common/BaseHeader'
import BasePage from '@/components/common/BasePage'
import CustomMenu from '@/components/CustomMenu'
import CustomersImageList from '@/components/CustomersImageList'
import EnquiryForm from '@/components/EnquiryForm'
import GalleryBanner from '@/components/GalleryBanner'
import HighlightAlaCarteMenu from '@/components/HighlightAlaCarteMenu'
import HighlightMenus from '@/components/HighlightMenus'
import MenuApi from '@/api/MenuApi'
import MenuCardList from '@/components/MenuCardList'
import PageFooter from '@/components/PageFooter'
import PageHeader from '@/components/PageHeader'
import cookies from '@/assets/js/cookies'
import intersection from 'lodash/intersection'
import isEmpty from 'lodash/isEmpty'
import { mapGetters } from 'vuex'
import { scrollWindowToElement } from '@/assets/js/common'

export default {
  name: 'catering-page',
  metaInfo() {
    const title = 'Grain | Catering experiences in Singapore'
    const description = 'Our signature buffet experience for all events. Now available in bento boxes.'
    return {
      title,
      meta: [
        { name: 'robots', content: process.env.VUE_APP_ENVIRONMENT === 'production' ? 'all' : 'none' },
        {
          vmid: 'og:title',
          property: 'og:title',
          content: title
        },
        {
          vmid: 'description',
          name: 'description',
          content: description
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: description
        }
      ]
    }
  },
  components: {
    BasePage,
    PageHeader,
    PageFooter,
    BannerContent,
    BaseHeader,
    CustomMenu,
    GalleryBanner,
    HighlightMenus,
    HighlightAlaCarteMenu,
    MenuCardList,
    CustomersImageList,
    EnquiryForm
  },
  data() {
    return {
      isBannerVideo: true,
      videoPlayed: false,
      menuCards: [],
      loadingMenuCards: false,
      hasAlaCarteMenu: false,
      menuFilterVisible: false // hiding in anticipation of covid phase 2 updates
    }
  },
  computed: {
    ...mapGetters(['getSelectedPax', 'getSelectedAbout', 'getIframeMenu', 'user/features']),
    features() {
      return this['user/features']
    },
    bannerSrc() {
      return require('@/assets/images/main-banner.jpg')
    },
    bannerTitle() {
      return this.$t('home.bannerHeader')
    },
    bannerByline() {
      return this.$t('home.bannerText')
    },
    bannerCta() {
      return this.$t('home.bannerCta')
    },
    selectedTags() {
      let tags = []
      this.getSelectedAbout.forEach((value) => {
        tags = tags.concat(
          value.split('/').map((tag) => {
            return tag.substr(0, 1).toUpperCase() + tag.substr(1)
          })
        )
      })
      return tags
    },
    beforeFestiveEndDate() {
      return this.$momenttz().startOf('day').toDate() <= CNY_END_DATE
    },
    beforeFestiveAlaCarteEndDate() {
      return this.$momenttz().startOf('day').toDate() <= CNY_END_DATE
    },
    sundayAnnouncementBannerVisible() {
      return this.$momenttz().startOf('day').toDate() <= SUNDAY_ANNOUNCEMENT_END_DATE
    },
    festiveSurchargeNoticeVisible() {
      return this.$momenttz().startOf('day').toDate() <= FESTIVE_SURCHARGE_END_DATE
    },
    highlightMenus() {
      if (this.menuCards) {
        return this.menuCards.filter((menuCard) => {
          return menuCard.type === 'HighlightMenu'
        })
      }
      return []
    },
    standardBuffetMenus() {
      if (this.menuCards) {
        return filterStandardBuffetMenus(this.menuCards)
      }
      return []
    },
    partySetMenus() {
      if (this.highlightMenus) {
        return filterPartySetMenus(this.highlightMenus)
      }
      return []
    },
    bentoMenus() {
      if (this.highlightMenus) {
        return filterBentoMenus(this.highlightMenus)
      }
      return []
    },
    christmasMenus() {
      if (this.highlightMenus) {
        return filterChristmasMenus(this.highlightMenus)
      }
      return []
    },
    cnyMenus() {
      if (this.highlightMenus) {
        return filterCnyMenus(this.highlightMenus)
      }
      return []
    },
    nationalDayMenus() {
      if (this.highlightMenus) {
        const order = [25, 73, 71, 72]
        const menus = filterNationalDayMenus(this.highlightMenus)

        return menus.sort((a, b) => {
          return order.indexOf(a.id) - order.indexOf(b.id)
        })
      }
      return []
    },
    weddingMenus() {
      if (this.highlightMenus) {
        return this.highlightMenus.filter((menuCard) => WEDDING_MENU_IDS.includes(menuCard.id))
      }
      return []
    },
    healthyMenus() {
      if (this.highlightMenus) {
        return this.highlightMenus.filter((menuCard) => menuCard.id === MENU_IDS.healthyBuffet)
      }
      return []
    },
    regularMenus() {
      if (this.menuCards) {
        return this.menuCards.filter((menuCard) => {
          return !['HighlightMenu', 'SeminarMenu'].includes(menuCard.type)
        })
      }
      return []
    },
    introMenu() {
      if (this.menuCards) {
        return this.menuCards.find(menuCard => menuCard.id === 86)
      }
      return null
    },
    miniBuffetMenus() {
      if (this.highlightMenus) {
        return filterMiniBuffetMenus(this.highlightMenus)
      }
      return []
    },
    filtersPresent() {
      if (this.menuFilterVisible) {
        return this.selectedTags.length > 0 || this.getSelectedPax
      }
      return false
    },
    filteredMenuCards() {
      if (this.regularMenus.length > 0) {
        if (this.filtersPresent) {
          return this.regularMenus.filter((menuCard) => {
            return this.filterMenu(menuCard)
          })
        }
        return this.regularMenus
      }
      return []
    },
    filteredOutMenuCards() {
      if (this.regularMenus.length > 0) {
        if (this.filtersPresent) {
          return this.regularMenus.filter((menuCard) => {
            return !this.filterMenu(menuCard)
          })
        }
      }
      return []
    }
  },
  created() {
    this.$store.dispatch(SET_CHECKOUT_ERRORS_VISIBLE, false)
    this.fetchMenus()

    if (this.$route.query.r) {
      // set cookie to expire in 7 days
      cookies.setCookie(
        'referred_by',
        this.$route.query.r,
        new Date(new Date().getTime() + 86400 * 1000 * 7).toGMTString()
      )
    }

    if (this.isBannerVideo) {
      this.$nextTick(() => {
        const video = document.querySelector('.BannerVideo > video')
        video.addEventListener('play', () => {
          this.videoPlayed = true
        })
      })
    } else {
      this.videoPlayed = true
    }
  },
  methods: {
    async fetchMenus() {
      this.loadingMenuCards = true
      const result = await MenuApi.index()
      this.loadingMenuCards = false
      this.menuCards = result.menus.filter((menu) => {
        return isMenuVisible(menu)
      })
    },
    contact() {
      const enquirySection = document.querySelector('.EnquirySection')
      scrollWindowToElement(enquirySection, -10)
    },
    search() {
      const menuCardListSection = document.querySelector('.MenuCardListSection')
      scrollWindowToElement(menuCardListSection, -10)
    },
    filterMenu(menuCard) {
      if (this.filtersPresent) {
        let menuHasSelectedTag = true
        if (this.selectedTags.length > 0) {
          menuHasSelectedTag = intersection(menuCard.tags, this.selectedTags).length > 0
        }
        let menuMeetsMinimumPax = true
        if (this.getSelectedPax) {
          const menuConfigMinimumPax = menuCard.menuConfigs.map((menuConfig) => menuConfig.minimumPax)
          const minimumPax = Math.min(...menuConfigMinimumPax)
          menuMeetsMinimumPax = this.getSelectedPax >= minimumPax
        }
        return menuHasSelectedTag && menuMeetsMinimumPax
      }
      return true
    },
    selectMenu(menu) {
      this.$store.dispatch(RESET_ORDER_STATE)
      this.$store.dispatch(SET_MENU_ID, menu.id)
      this.$store.dispatch(SET_MENU_COVER, menu.cover)
      if (isEmpty(menu.eventType)) {
        this.$router.push('/catering/select-menu-items')
      } else {
        this.$router.push({ path: `/catering/select-menu-items/${menu.id}` })
      }
    },
    onClearIframeMenu() {
      this.$store.dispatch('setIframeMenu', null)
    },
    triggerProductClicked(menu, type) {
      this.trackProductClicked(menu.id, menu.name, type)
    },
    triggerProductViewed(menu, type) {
      this.trackProductViewed(menu.id, menu.name, type)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/base';

@import '~@/assets/css/_shared_variables.sass';

.CateringPage {
  &.VideoPaused {
    &::v-deep .HomeBanner,
    &::v-deep .BannerVideo {
      .PageHeaderContentWrapper {
        pointer-events: none;
      }
    }
  }

  /deep/ .HomeBanner,
  /deep/ .BannerVideo {
    background-position: center 35%;

    @media #{$small-only} {
      height: 480px;
      min-height: 480px !important;
      background-position: 30% top;
    }

    @media #{$xsmall-only} {
      background-position: 20% top;
    }

    .CopyContainer {
      max-width: 895px;

      @media #{$small-only} {
        padding-bottom: $space-m;
      }
    }
  }

  /deep/ .BannerContent {
    .BaseHeader {
      .HeaderTitle {
        font-family: $font-family;
        text-transform: none;
      }

      .HeaderByline {
        &,
        a {
          color: $white;
        }
      }

      .byline-question {
        font-weight: 700;
      }

      .HeaderCta {
        margin-bottom: 0;
        font-weight: 700;
        color: $white;
        text-align: center;
        @extend %display_light;
        text-shadow: rgba(0, 0, 0, 0.5) 0 2px 4px;
        pointer-events: auto;

        @media #{$small-only} {
          font-size: 1rem;
          line-height: 1.25rem;
        }
      }
    }
  }

  .BodyContent {
    position: relative;
    margin-top: $space-m;

    .Section {
      margin-bottom: $space-l;

      &:last-child {
        margin-bottom: 0;
      }

      &.MenuCardListSection {
        margin-bottom: $space-l;

        .MenusLoading {
          text-align: center;
        }

        .Announcement {
          padding: $space-l;
          margin-bottom: $space-m;
          color: $white;
          background-image: url('~@/assets/images/announcement-background.jpg');
          background-position: 17% center;
          background-size: cover;
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);

          .AnnouncementSubParagraphs {
            max-width: 60%;
            margin-top: 2rem;

            .column {
              float: left;
              width: 45%;
              padding-right: 2rem;

              &:last-child {
                padding-right: 0;
              }
            }

            /* Clear floats after the columns */

            .row::after {
              display: table;
              clear: both;
              content: '';
            }
          }

          .clear {
            clear: both;
          }

          @media #{$small-only} {
            padding: $space-m;
          }

          > * {
            max-width: 460px;
          }

          .AnnouncementTitle {
            @extend %display_medium;
            margin-bottom: $space-xs;
          }

          .AnnouncementBody {
            @extend %body;
          }
        }

        .festive-surcharge-notice {
          margin-bottom: $space-m;
          color: $white;
          text-align: center;
          text-shadow: 0 0 8px black;
        }

        .WeddingBanner {
          position: relative;
          height: 400px;
          margin-bottom: $space-m;
          overflow: hidden;
          background-image: url('~@/assets/images/weddings-banner.jpg');
          background-position: 20% top;
          background-size: cover;

          .WeddingContent {
            position: absolute;
            top: 50%;
            left: 50%;
            box-sizing: border-box;
            width: 100%;
            max-width: 800px;
            padding: 0 $space-m;
            color: $white;
            text-align: center;
            transform: translate(-50%, -50%);

            .WeddingTitle {
              margin-top: $space-xl;
              margin-bottom: $space-l;
              @extend %display_large;
              font-weight: normal;

              .TitleBy {
                font-size: 22px;
                margin-left: 74px;
              }

              :not(.TitleBy) {
                font-family: $wedding-stylised-font;
                font-size: 112px;
              }
            }

            .WeddingByline {
              max-width: 450px;
              margin-left: auto;
              margin-right: auto;
              margin-bottom: $space-l;
              @extend %display_light;
            }
          }
        }

        .FilteredMenuHeader {
          margin-top: $space-xl;
          margin-bottom: $space-l;
          @extend %display_medium;
          text-align: center;
        }

        .MenuCardList + .FilteredMenuHeader {
          margin-top: $space-xl - $space-m;
        }
      }

      &.CustomersSection {
        padding: 0 $space-s;
        margin-top: $space-xxl;
        margin-bottom: $space-xxl;
        text-align: center;
      }

      &.EnquirySection {
        .BaseHeader {
          text-align: center;

          /deep/ .Button--text {
            white-space: nowrap;
          }
        }

        .AfterEnquiryForm {
          max-width: 500px;
          margin: 0 auto;
        }
      }
    }

    /deep/ {
      .MenuCardListSection {
        margin-top: -110px;
      }
    }

    .FilteredMenusHeader,
    .FilteredOutMenusHeader {
      margin-bottom: 0.5rem;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
    }

    .FilteredOutMenusHeader {
      margin-top: 1.5rem;
    }

    /deep/ .Display {
      font-family: $display-font;
      text-transform: uppercase;
      white-space: nowrap;
    }
  }

  .menu-iframe {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;

    .modal-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.8);
      z-index: 100;
    }

    .close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 110;
      width: 40px;
      height: 40px;
      background: black;
      color: white;
      font-size: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 40px;
      cursor: pointer;
    }

    .frame-container {
      background: #eae6e1;
      position: relative;
      display: flex;
      width: calc(100% - 40px);
      max-width: 420px;
      height: calc(100% - 40px);
      max-height: 450px;
      z-index: 110;
    }

    iframe {
      height: 100%;
      width: 100%;
      border: 0;

      @media #{$small-only} {
        width: 100%;
        height: 100%;
        max-height: initial;
      }
    }
  }
}
</style>
