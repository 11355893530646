<template>
  <div class="GalleryBanner">
    <a
      class="Button Button--primary ViewGalleryButton"
      href="/gallery">
      {{ $t('galleryBanner.buttonTitle') }}
    </a>
  </div>
</template>

<script>
export default {
  name: 'gallery-banner'
}
</script>

<style lang="scss" scoped>
  @import "~@/assets/css/base";
  @import "~@/assets/css/_shared_variables.sass";

  .GalleryBanner {
    position: relative;
    height: 150px;
    width: 100%;
    background-size: cover;
    background-position: center center;
    background-image: url('~@/assets/images/gallery-banner-mobile.jpg');
    margin-top: $space-xxl;
    overflow: hidden;

    @media #{$medium-up} {
        height: 400px;
        background-image: url('~@/assets/images/gallery-banner.jpg');
    }
  }

  .ViewGalleryButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 44px;
    line-height: 44px;
    font-weight: bold;
    text-align: center;

    @media #{$small-only} {
      padding: 0 $space-m;
    }
  }
</style>
