<template>
  <card class="HighlightAlaCarteMenu">
    <div class="AlaCarteMenuCover"/>
    <div class="AlaCarteMenu">
      <div class="MenuTitle">
        {{ festiveAlaCarteMenu.name }}
        <div class="MenuByline">
          {{ festiveAlaCarteMenu.byline }}
        </div>
      </div>
      <div class="MenuNotice">
        {{ festiveAlaCarteMenu.notice }}
      </div>
      <div class="Actions">
        <div
          class="Button Button--primary OrderButton"
          @click="selectMenu">
          {{ $t('menuCard.orderButtonTitle') }}
        </div>
      </div>
    </div>
  </card>
</template>

<script>
import { CHRISTMAS_ALA_CARTE_MENU, CNY_ALA_CARTE_MENU, sortedHighlightMenus } from '@/assets/js/highlight-menu-helper'
import Card from '@/components/common/Card'

export default {
  name: 'highlight-ala-carte-menu',
  components: {
    Card
  },
  props: {
    menus: {
      type: Array,
      required: true
    }
  },
  computed: {
    festiveAlaCarteMenu() {
      return this.christmasAlaCarteMenu
    },
    cnyAlaCarteMenu() {
      return CNY_ALA_CARTE_MENU
    },
    christmasAlaCarteMenu() {
      return CHRISTMAS_ALA_CARTE_MENU
    },
    sortedMenus() {
      return sortedHighlightMenus(this.menus)
    }
  },
  methods: {
    selectMenu() {
      this.$emit('selectMenu', Object.assign({
        cover: this.sortedMenus[0].cover
      }, this.festiveAlaCarteMenu))
      this.triggerProductClicked(this.$t('menuCard.orderButtonTitle'))
    },
    triggerProductClicked(type) {
      this.trackProductClicked(this.festiveAlaCarteMenu.id, this.festiveAlaCarteMenu.name, type)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
@import "~@/assets/css/_shared_variables.sass";

.HighlightAlaCarteMenu {
  position: relative;
  margin-bottom: 1rem;
  overflow: hidden;
  background-color: $ink;

  /deep/ > .CardContent {
    padding: 0;
  }
  .AlaCarteMenuCover {
    position: absolute;
    top: 0;
    left: 0;
    width: 300px;
    height: 100%;
    // background-image: url('~@/assets/images/christmas-2019/christmas-ala-carte-banner.jpg');
    background-size: cover;
    background-position: center 25%;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);

    @media (max-width: 640px) {
      position: relative;
      width: 100%;
      height: 100%;
      height: 60vw;
      // background-image: url('~@/assets/images/christmas-2019/christmas-ala-carte-banner-mobile.jpg');
    }
  }
  .AlaCarteMenu {
    margin-left: 300px;
    padding: 1rem;
    background-color: black;

    @media (max-width: 640px) {
      margin-left: 0;
    }
    .MenuTitle {
      margin-bottom: 0.5rem;
      @extend %display_medium;
      color: $ink;

      @media (max-width: 640px) {
        margin-bottom: $space-xl;
      }
    }
    .MenuByline {
      margin-top: 0.25rem;
      line-height: 20px;
      font-size: 14px;
      font-weight: normal;
      color: $gray-darker;
    }
    .MenuNotice {
      margin-bottom: $space-xl;
      font-size: 14px;
      color: $gray-dark;

      @media (max-width: 640px) {
        margin-bottom: 1rem;
      }
    }
    .MenuTitle, .MenuByline, .MenuNotice {
      color: white;
    }
    .Actions {
      position: relative;
      text-align: right;

      @media (max-width: 640px) {
        text-align: left;
      }
    }
    .OrderButton {
      height: 44px;
      line-height: 44px;
      padding: 0 $space-l;
      @extend %heading;
    }
  }
}
</style>
