<template>
  <div class="CustomMenu">
    <div class="CustomMenuBackground"/>
    <div class="CustomMenuContent">
      <h2
        class="CustomMenuTitle">
        {{ $t('customMenu.title') }}
      </h2>
      <div
        class="CustomMenuByline">
        <span v-html="$t('customMenu.byline')"/>
      </div>
      <div class="CustomMenuSection">
        <div class="CustomMenuSubHeader">
          {{ $t('customMenu.section1Header') }}
        </div>
        <div>{{ $t('customMenu.section1Body') }}</div>
      </div>
      <div
        class="Button Button--primary CtaButton"
        @click="selectMenu(menu)">
        {{ $t('customMenu.buttonTitle') }}
      </div>
      <a
        slot="right"
        class="Button--text Button--text DownloadMenu"
        :href="menu.pdf"
        target="_blank">
        {{ $t('menuCard.downloadLinkTitle') }}
      </a>
    </div>
  </div>
</template>

<script>
import { scrollWindowToElement } from '@/assets/js/common'

export default {
  name: 'custom-menu',
  props: {
    menu: {
      type: Object,
      required: true
    }
  },
  methods: {
    contact() {
      const enquirySection = document.querySelector('.EnquirySection')
      scrollWindowToElement(enquirySection, -10)
    },
    selectMenu(menu) {
      this.$emit('selectMenu', menu)
      this.triggerProductClicked(this.$t('customMenu.buttonTitle'))
    },
    triggerProductClicked(type) {
      this.trackProductClicked(this.menu.id, this.$t('customMenu.title'), type)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/base';
@import '~@/assets/css/_shared_variables.sass';

$custom-menu-dir: '~@/assets/images/custom-menu';

.CustomMenu {
  background-color: $white;
  margin-bottom: $space-m;
  @extend %body;
  font-weight: normal;
  color: $gray-darker;

  @media #{$medium-up} {
    display: flex;
    flex-flow: row-reverse;
  }

  .CustomMenuBackground {
    background-image: url('#{$custom-menu-dir}/cloud_cafe_by_grain.png');
    background-position: center center;
    background-size: cover;
    height: 200px;

    @media #{$medium-up} {
      height: auto;
      width: 50%;
    }
  }

  .CustomMenuContent {
    padding: $space-l;

    @media #{$medium-up} {
      width: calc(50% - #{$space-l} * 2);
    }

    @media #{$small-only} {
      padding: $space-m;
    }
  }

  .CustomMenuTitle,
  .CustomMenuByline {
    color: $ink;
  }

  .CustomMenuTitle {
    margin-bottom: $space-xxs;
    font-size: 1.5rem;
    line-height: 2rem;
    @extend %bold_weight;
  }

  .CustomMenuByline {
    margin-bottom: $space-l;
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;

    @media (max-width: 768px) {
      max-width: 60%;
    }

    @media #{$small-only} {
      max-width: none;
      margin-bottom: $space-l;
    }
  }

  .CustomMenuSection {
    margin-bottom: $space-m;

    .CustomMenuSubHeader {
      @extend %bold_weight;
      color: $ink;
    }
  }

  .CtaButton {
    margin-top: $space-xs;
  }

  .DownloadMenu {
    display: block;
    margin-top: $space-m;
    margin-bottom: $space-l;
    @extend %small;

    @media #{$small-only} {
      margin-bottom: 0;
    }
  }
}
</style>
