<template>
  <div
    class="HighlightMenus"
    :class="{
      Standard: isStandardMenu,
      Healthy: isHealthyMenu,
      Bento: isBentoMenu,
      Christmas: isChristmasMenu,
      Cny: isCnyMenu,
      Mini: isMiniMenu,
      National: isNationalDayMenu,
    }">
    <h2
      class="HighlightTitle"
      v-html="highlightTitle"/>
    <div
      class="HighlightByline"
      v-html="highlightByline"/>
    <equalizer
      v-if="isMenuPerRow"
      class="HighlightMenusWrapper PerRow"
      :classes="['CustomisedContentBefore']"
      :config="{
        641: 3,
        0: 1
      }">
      <div class="MenusContainer">
        <div
          v-for="(menuDetails, index) in sortedMenus"
          :key="`highlight-menu-${index}`"
          class="HighlightMenu">
          <div>
            <menu-pricing
              :title="menuDetails.name"
              :byline="menuDetails.byline"
              :menuConfigs="[]"/>
            <div class="HighlightActionsWrapper">
              <div class="HighlightActions">
                <div
                  class="Button Button--primary OrderButton"
                  @click="selectMenu(menuDetails)">
                  {{ $t('menuCard.orderButtonTitle') }}
                </div>
                <div
                  v-if="menuDetails.enableMultiPdf"
                  slot="right"
                  class="Button--text Button--text DownloadMenu"
                  @click="onDownloadMenu(menuDetails)">
                  {{ $t('menuCard.downloadLinkTitle') }}
                </div>
                <a
                  v-else-if="menuDetails.pdf"
                  slot="right"
                  class="Button--text Button--text DownloadMenu AddOnsMenu"
                  :href="menuDetails.pdf"
                  target="_blank">
                  {{ $t('menuCard.downloadLinkTitle') }}
                </a>
                <a
                  v-if="menuDetails.id === 82"
                  slot="right"
                  class="Button--text Button--text DownloadMenu AddOnsMenu"
                  :href="'https://grain.com.sg/catering/select-menu-items/83'"
                  target="_blank">
                  {{ $t('menuCard.addOnsOnlyMenu') }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <menu-pricing
        :menuConfigs="sortedMenus[0].menuConfigs"
        :customised="true"/>
    </equalizer>
    <equalizer
      v-else
      class="HighlightMenusWrapper"
      :classes="['MenuByline', 'MenuPricing']"
      :config="{
        641: (isChristmasMenu || isCnyMenu) ? 2 : isStandardMenu ? 3 : 1,
        0: 1
      }">
      <div
        v-for="(menuDetails, index) in sortedMenus"
        :key="`highlight-menu-${index}`"
        class="HighlightMenu">
        <template v-if="sortedMenus.length > 1">
          <menu-pricing
            :title="menuDetails.name"
            :byline="menuDetails.byline"
            :menuConfigs="menuDetails.menuConfigs"/>
          <div
            v-if="hasIndividualOrderButton"
            class="HighlightMenuActionsWrapper">
            <div class="HighlightMenuActions">
              <div
                class="Button Button--primary OrderButton"
                @click="selectMenu(menuDetails)">
                {{ $t('menuCard.orderButtonTitle') }}
              </div>
              <div
                v-if="menuDetails.enableMultiPdf"
                slot="right"
                class="Button--text Button--text DownloadMenu"
                @click="onDownloadMenu(menuDetails)">
                {{ $t('menuCard.downloadLinkTitle') }}
              </div>
              <a
                v-else-if="menuDetails.pdf"
                slot="right"
                class="Button--text Button--text DownloadMenu AddOnsMenu"
                :href="menuDetails.pdf"
                target="_blank">
                {{ $t('menuCard.downloadLinkTitle') }}
              </a>
            </div>
          </div>
        </template>
        <template v-else>
          <menu-pricing
            :menuConfigs="menuDetails.menuConfigs"
            :showDetails="true"/>
          <img
            v-if="isHealthyMenu"
            :src="require('@/assets/images/healthy-menu/healthy-menu-hpb.png')"
            class="HpbIcon">
        </template>
        <template v-if="(index === sortedMenus.length - 1) && !hasIndividualOrderButton">
          <div class="HighlightActionsWrapper">
            <div class="HighlightActions">
              <div
                class="Button Button--primary OrderButton"
                @click="selectMenu(sortedMenus[0])">
                {{ $t('menuCard.orderButtonTitle') }}
              </div>
              <div
                v-if="sortedMenus[0].enableMultiPdf"
                slot="right"
                class="Button--text Button--text DownloadMenu"
                @click="onDownloadMenu(sortedMenus[0])">
                {{ $t('menuCard.downloadLinkTitle') }}
              </div>
              <a
                v-else-if="sortedMenus[0].pdf"
                slot="right"
                class="Button--text Button--text DownloadMenu AddOnsMenu"
                :href="sortedMenus[0].pdf"
                target="_blank">
                {{ $t('menuCard.downloadLinkTitle') }}
              </a>
            </div>
          </div>
        </template>
      </div>
    </equalizer>
  </div>
</template>

<script>
import MenuPricing from '@/components/MenuPricing'
import { SET_IFRAME_MENU } from '../../actions'
import equalizer from 'vue-equalizer'
import { sortedHighlightMenus } from '@/assets/js/highlight-menu-helper'

export default {
  name: 'highlight-menus',
  components: {
    MenuPricing,
    equalizer
  },
  props: {
    menus: {
      type: Array,
      required: true
    },
    type: {
      type: String,
      default: null
    }
  },
  computed: {
    isMenuPerRow() {
      if (this.isChristmasMenu || this.isCnyMenu) {
        return true
      }
      return false
    },
    sortedMenus() {
      return sortedHighlightMenus(this.menus)
    },
    isStandardMenu() {
      return this.type === 'standard'
    },
    isHealthyMenu() {
      return this.type === 'healthy'
    },
    isBentoMenu() {
      return this.type === 'bento'
    },
    isMiniMenu() {
      return this.type === 'mini'
    },
    isChristmasMenu() {
      return this.type === 'christmas'
    },
    isCnyMenu() {
      return this.type === 'cny'
    },
    isNationalDayMenu() {
      return this.type === 'national'
    },
    hasIndividualOrderButton() {
      return this.isStandardMenu || this.isChristmasMenu || this.isCnyMenu || this.isMiniMenu || this.isNationalDayMenu
    },
    highlightTitle() {
      if (this.isStandardMenu) {
        return this.$t('highlightMenus.standardMenu.title')
      }
      if (this.isHealthyMenu) {
        return this.$t('highlightMenus.healthyMenu.title')
      }
      if (this.isBentoMenu) {
        return this.$t('highlightMenus.bentoMenu.title')
      }
      if (this.isMiniMenu) {
        return this.$t('highlightMenus.miniMenu.title')
      }
      if (this.isChristmasMenu) {
        return this.$t('highlightMenus.christmasMenu.title')
      }
      if (this.isCnyMenu) {
        return this.$t('highlightMenus.cnyMenu.title')
      }
      if (this.isNationalDayMenu) {
        return this.$t('highlightMenus.nationalMenu.title')
      }
      return this.$t('highlightMenus.title')
    },
    highlightByline() {
      if (this.isStandardMenu) {
        return this.$t('highlightMenus.standardMenu.byline')
      }
      if (this.isHealthyMenu) {
        return this.$t('highlightMenus.healthyMenu.byline')
      }
      if (this.isBentoMenu) {
        return this.$t('highlightMenus.bentoMenu.byline')
      }
      if (this.isMiniMenu) {
        return this.$t('highlightMenus.miniMenu.byline')
      }
      if (this.isChristmasMenu) {
        return this.$t('highlightMenus.christmasMenu.byline')
      }
      if (this.isCnyMenu) {
        return this.$t('highlightMenus.cnyMenu.byline')
      }
      if (this.isNationalDayMenu) {
        return this.$t('highlightMenus.nationalMenu.byline')
      }
      return this.$t('highlightMenus.byline')
    }
  },
  methods: {
    selectMenu(menu) {
      this.$emit('selectMenu', menu)
      this.triggerProductClicked(this.$t('menuCard.orderButtonTitle'))
    },
    onDownloadMenu(menu) {
      this.triggerProductClicked(this.$t('menuCard.downloadLinkTitle'))
      this.$nextTick(() => {
        this.triggerProductViewed(this.$t('menuCard.downloadLinkTitle'))
      })
      this.$store.dispatch(SET_IFRAME_MENU, menu)
    },
    triggerProductClicked(type) {
      this.trackProductClicked(this.sortedMenus[0].id, this.$t('highlightMenus.title'), type)
    },
    triggerProductViewed(type) {
      this.trackProductViewed(this.sortedMenus[0].id, this.$t('highlightMenus.title'), type)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/base';

@import '~@/assets/css/_shared_variables.sass';

$bento-menu-dir: '~@/assets/images/bento-menu';
$christmas-menu-dir: '~@/assets/images/christmas-2023';
$cny-menu-dir: '~@/assets/images/cny-2024';
$healthy-menu-dir: '~@/assets/images/healthy-menu';
$mini-menu-dir: '~@/assets/images/mini-menu';
$national-menu-dir: '~@/assets/images/national-menu';
$party-set-menu-dir: '~@/assets/images/party-set-menu';
$standard-menu-dir: '~@/assets/images/standard-menu';

$media-xxsmall: 'only screen and (max-width: 360px)';

.HighlightMenus {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  max-width: $row-width;
  padding: $space-l;
  margin-bottom: $space-m;
  overflow: hidden;
  text-align: left;
  background-color: $primary-bg-light;
  background-image: url('#{$party-set-menu-dir}/party-set-menu-banner-320px.jpg');
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 100%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);

  @media #{$medium-up} {
    display: flex;
    flex-direction: column;
    background-image: url('#{$party-set-menu-dir}/party-set-menu-banner-1138px.jpg');
    background-position-x: 292px;
    background-size: cover;
  }

  @media #{$tablet-up} {
    background-size: contain;
  }

  @media #{$large-up} {
    background-position-x: right;
  }

  @media #{$small-only} {
    padding: $space-m;
  }

  &:not(.Standard):not(.Healthy):not(.Bento) {
    .HighlightActionsWrapper {
      .DownloadMenu {
        background-color: $primary-bg-light;
        text-align: center;

        @media #{$small-only} {
          text-align: left;
        }
      }

      .AddOnsMenu {
        background-color: $primary-bg-light;
        text-align: center;
        margin-top: $space-s;
      }
    }
  }

  .HighlightActionsWrapper {
    .DownloadMenu {
      text-align: center;

      @media #{$small-only} {
        text-align: left;
      }
    }

    .AddOnsMenu {
      background-color: $primary-bg-light;
        text-align: center;
        margin-top: $space-m;
    }
  }

  &.Christmas {
    background-image: url('#{$christmas-menu-dir}/christmas-banner-640px.jpg');
    background-position: left top;
    background-size: 100%;

    @media #{$tablet-up} {
      min-height: 400px;
      background-image: url('#{$christmas-menu-dir}/christmas-banner-1138px.jpg');
      background-position: 30% center;
      background-size: cover;
    }

    @media #{$large-up} {
      background-position: -100px center;
    }

    .HighlightTitle {
      @media #{$tablet-down} {
        margin-top: 30%;
      }
    }

    .HighlightMenu {
      @media #{$medium-only} {
        box-sizing: border-box;
        max-width: 50%;
      }

      @media #{$small-only} {
        &:not(:first-child) {
          margin-top: $space-l;
        }
      }

      .MenuPricing {
        width: 234px;
        margin-bottom: 0;

        @media #{$small-only} {
          width: 100%;
        }

        /deep/ .MenuByline {
          color: $gray-darker;
        }
      }
    }
  }

  &.Cny {
    background-image: url('#{$cny-menu-dir}/cny-banner-640px.jpg');
    background-position: left top;
    background-size: 100%;

    @media #{$tablet-up} {
      min-height: 400px;
      background-image: url('#{$cny-menu-dir}/cny-banner-1138px.jpg');
      background-position: 30% center;
      background-size: cover;
    }

    @media #{$large-up} {
      background-position: -100px center;
    }

    .HighlightTitle {
      @media #{$tablet-down} {
        margin-top: 30%;
      }
    }

    .HighlightMenu {
      @media #{$medium-only} {
        box-sizing: border-box;
        max-width: 50%;
      }

      @media #{$small-only} {
        &:not(:first-child) {
          margin-top: $space-l;
        }
      }

      .MenuPricing {
        width: 234px;
        margin-bottom: 0;

        @media #{$small-only} {
          width: 100%;
        }

        /deep/ .MenuByline {
          color: $gray-darker;
        }
      }
    }
  }

  &.Standard {
    background-image: url('#{$standard-menu-dir}/standard-banner-640px.jpg');
    background-position: left top;
    background-size: 100%;

    @media #{$medium-up} {
      min-height: 425px;
      background-image: url('#{$standard-menu-dir}/standard-banner-1138px.jpg');
      background-position: 20% bottom;
      background-size: cover;
    }

    .HighlightTitle {
      @media #{$small-only} {
        margin-top: 30%;
      }

      @media #{$xsmall-only} {
        margin-top: 35%;
      }

      @media #{$media-xxsmall} {
        margin-top: 40%;
      }
    }

    .HighlightMenu {
      @media #{$medium-only} {
        box-sizing: border-box;
        max-width: 33.3333%;
      }

      @media #{$small-only} {
        &:not(:first-child) {
          margin-top: $space-l;
        }
      }

      > .MenuPricing {
        width: 234px;
        margin-bottom: 0;
      }

      .DownloadMenu {
        background-color: #f8f3ef;
      }
    }
  }

  &.Healthy {
    background-image: url('#{$healthy-menu-dir}/healthy-menu-320px.jpg');
    background-position: right top;
    background-size: cover;

    @media #{$medium-up} {
      background-image: url('#{$healthy-menu-dir}/healthy-menu-768px.jpg');
    }

    @media #{$large-up} {
      background-image: url('#{$healthy-menu-dir}/healthy-menu-1138px.jpg');
    }

    .HighlightTitle {
      @media #{$small-only} {
        margin-top: 40%;
      }

      @media #{$xsmall-only} {
        margin-top: 45%;
      }

      @media #{$media-xxsmall} {
        margin-top: 50%;
      }
    }

    .HpbIcon {
      display: block;
      width: 278px;
      max-width: 100%;
      margin-top: $space-m;

      @media #{$tablet-down} {
        margin-bottom: $space-xl;
      }
    }
  }

  &.Bento {
    background-image: url('#{$bento-menu-dir}/bento-banner-640px.jpg');
    background-position: left top;
    background-size: 100%;

    @media #{$medium-up} {
      height: 425px;
      background-image: url('#{$bento-menu-dir}/bento-banner-1138px.jpg');
      background-position: left bottom;
      background-size: 1138px;
    }

    .HighlightTitle {
      @media #{$small-only} {
        margin-top: 30%;
      }
    }

    .HighlightMenu {
      @media #{$medium-only} {
        box-sizing: border-box;
        max-width: 25%;
      }

      .MenuPricing {
        @media #{$small-only} {
          width: 100%;
        }

        /deep/ .MenuByline {
          color: $gray-darker;
        }
      }
    }
  }

  &.Mini {
    background-image: url('#{$mini-menu-dir}/mini-banner-640px.jpg');
    background-position: left top;
    background-size: 100%;

    @media #{$medium-up} {
      min-height: 425px;
      background-image: url('#{$mini-menu-dir}/mini-banner-1138px.jpg');
      background-position: 20% bottom;
      background-size: cover;
    }

    .HighlightTitle {
      @media #{$small-only} {
        margin-top: 30%;
      }

      @media #{$xsmall-only} {
        margin-top: 35%;
      }

      @media #{$media-xxsmall} {
        margin-top: 40%;
      }
    }

    .HighlightMenu {
      @media #{$medium-only} {
        box-sizing: border-box;
        max-width: 33.3333%;
      }

      @media #{$small-only} {
        &:not(:first-child) {
          margin-top: $space-l;
        }
      }

      > .MenuPricing {
        width: 234px;
        margin-bottom: 0;
      }

      .DownloadMenu {
        background-color: #f8f3ef;
      }
    }
  }

  &.National {
    background-image: url('#{$national-menu-dir}/national-banner-640px.jpg');
    background-position: right top;
    background-size: 100%;

    @media #{$medium-up} {
      min-height: 425px;
      background-image: url('#{$national-menu-dir}/national-banner-1138px.jpg');
      background-position: 20% bottom;
      background-size: cover;
    }

    .HighlightTitle {
      @media #{$small-only} {
        margin-top: 30%;
      }
    }

    .HighlightMenu {
      @media #{$medium-only} {
        box-sizing: border-box;
        max-width: 25%;
      }

      @media #{$small-only} {
        &:not(:first-child) {
          margin-top: $space-l;
        }
      }

      .MenuPricing {
        margin-bottom: 0;
        @media #{$small-only} {
          width: 100%;
        }
      }
    }
  }

  > * {
    position: relative;
  }

  .HighlightMenusWrapper {
    @media #{$medium-up} {
      height: 100%;
      flex-grow: 1;
    }

    &.PerRow {
      @media #{$medium-up} {
        display: flex;
      }

      .MenusContainer {
        @media #{$medium-up} {
          padding-right: $space-s;
        }

        @media #{$large-up} {
          padding-right: $space-l;
        }

        .HighlightMenu {
          display: block;
          padding-right: 0;
          padding-bottom: 0;

          @media #{$medium-only} {
            max-width: 100%;
          }

          &:not(:first-child) {
            padding-top: $space-l;
          }

          /deep/ .MenuPricing {
            @media #{$tablet-down} {
              width: 175px;
            }
          }

          .HighlightActionsWrapper {
            position: relative;

            .HighlightActions {
              display: inline-block;

              &,
              .DownloadMenu {
                text-align: left;
              }
            }
          }
        }

        & + .MenuPricing {
          width: 100%;
          max-width: 600px;

          @media #{$small-only} {
            padding-top: $space-xl;
          }

          /deep/ .GridRow {
            width: 100%;

            .GridColumn {
              @media #{$small-only} {
                margin-bottom: 0;
                width: 100% !important;

                &:not(:first-child) {
                  margin-top: $space-xl;
                }
              }
            }
          }
        }
      }
    }
  }

  .HighlightTitle,
  .HighlightByline {
    color: $ink;
  }

  .HighlightTitle {
    margin-top: 4rem;
    margin-bottom: $space-xxs;
    font-size: 1.5rem;
    line-height: 2rem;
    @extend %bold_weight;

    @media #{$medium-up} {
      margin-top: 0;
    }

    @media #{$small-only} {
      margin-top: 45%;
    }

    @media #{$xsmall-only} {
      margin-top: 50%;
    }

    @media #{$media-xxsmall} {
      margin-top: 55%;
    }
  }

  .HighlightByline {
    margin-bottom: $space-l;
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;

    @media (max-width: 768px) {
      max-width: 60%;
    }

    @media #{$small-only} {
      max-width: none;
      margin-bottom: $space-l;
    }
  }

  .HighlightPrice {
    margin-top: $space-m;
    font-size: 12px;
    color: $ink;
    @extend %bold_weight;

    .Price {
      @extend %display_pricing;
    }

    .PerGuest {
      position: relative;
      top: $space-xxs;
      vertical-align: top;
    }
  }

  .HighlightMenu {
    min-width: 140px;
    max-width: 100%;
    vertical-align: top;

    .MenuPricing {
      width: 195px;
      max-width: 100%;
      margin-bottom: $space-xl;

      @media #{$small-only} {
        margin-bottom: $space-l;
      }

      /deep/ {
        .MenuTitle,
        .MenuConfigPrice {
          color: $ink;
        }

        .MenuTitle {
          margin-bottom: $space-m;
        }

        .MenuByline {
          color: $gray-darker;
        }

        .Price {
          @extend %display_pricing;
        }

        .MenuConfigDetails {
          margin-top: $space-xxs;
          color: $gray-darker;
          @extend %body;
        }
      }
    }

    .HighlightMenuActionsWrapper {
      margin-top: $space-m;

      .HighlightMenuActions {
        display: inline-block;
        text-align: center;

        @media #{$small-only} {
          text-align: left;
        }

        .OrderButton {
          white-space: nowrap;
        }

        .DownloadMenu {
          display: block;
          margin-top: $space-m;
          @extend %small;

          @media #{$small-only} {
            margin-bottom: 0;
          }
        }
      }
    }

    &:last-child {
      &:not(:first-child) {
        @media #{$medium-down} {
          padding-bottom: $space-xxl;
        }

        @media #{$small-only} {
          padding-bottom: 0;
        }
      }

      .MenuPricing {
        margin-bottom: 0;
      }
    }

    @media #{$small-only} {
      width: 100% !important;
    }

    @media #{$medium-up} {
      display: inline-block;

      &:not(:last-child) {
        padding-right: $space-l;
      }

      .MenuPricing {
        display: inline-block;

        /deep/ .GridColumn {
          &:not(:last-child) {
            margin-bottom: $space-m;
          }
        }
      }
    }
  }

  .HighlightActionsWrapper {
    position: absolute;
    right: 0;
    bottom: 0;
    margin-top: $space-m;

    @media #{$small-only} {
      position: static;
    }

    .HighlightActions {
      text-align: center;

      @media #{$medium-down} {
        text-align: left;
        display: inline-block;
      }

      .DownloadMenu {
        display: block;
        margin-top: $space-m;
        @extend %small;

        @media #{$small-only} {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
