<template>
  <date-picker
    class="FormDatePicker"
    :selectedDate="selectedDate"
    :minDate="leadTimeLater.toISOString()"
    :placeholder="placeholder"
    inputClass="FormInput"
    v-on="$listeners"/>
</template>

<script>
import { getSameTimeInUTC, startOfDaysLater } from '@/assets/js/datetime'
import DatePicker from '@/components/common/DatePicker'
import { LEAD_TIME_IN_DAYS } from '@/assets/js/order-helper'

export default {
  name: 'form-date-picker',
  components: {
    DatePicker
  },
  props: {
    selectedDate: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    }
  },
  computed: {
    leadTimeLater() {
      return getSameTimeInUTC(startOfDaysLater(LEAD_TIME_IN_DAYS))
    }
  },
  created() {
    if (this.selectedDate && new Date(this.selectedDate) < this.leadTimeLater) {
      this.$emit('change', null)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
@import "~@/assets/css/form-input";
</style>
