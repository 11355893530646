<template>
  <form
    class="EnquiryForm"
    @submit.prevent="onSubmit">
    <two-columns class="FormInputRow">
      <template>
        <label
          for="contact_person"
          class="FormLabel">
          {{ $t('enquiryForm.contactPersonLabel') }}
        </label>
        <input
          id="contact_person"
          v-model="form.contactPerson"
          type="text"
          class="FormInput"
          required>
      </template>
      <template slot="right">
        <label class="FormLabel">
          {{ $t('enquiryForm.dateLabel') }}
        </label>
        <form-date-picker
          :selectedDate="form.date"
          @change="onChangeDate"/>
      </template>
    </two-columns>
    <div class="FormInputRow">
      <label
        for="email"
        class="FormLabel">
        {{ $t('enquiryForm.emailLabel') }}
      </label>
      <input
        id="email"
        v-model="form.email"
        type="email"
        class="FormInput"
        required>
    </div>
    <two-columns class="FormInputRow">
      <template>
        <label
          for="phone"
          class="FormLabel">
          {{ $t('enquiryForm.phoneLabel') }}
        </label>
        <input
          id="phone"
          v-model="form.phone"
          type="text"
          class="FormInput"
          required>
      </template>
      <template slot="right">
        <label
          for="headcount"
          class="FormLabel">
          {{ $t('enquiryForm.headcountLabel') }}
        </label>
        <input
          id="headcount"
          v-model="form.headcount"
          type="text"
          class="FormInput"
          required>
      </template>
    </two-columns>
    <div class="FormInputRow">
      <label
        for="company_name"
        class="FormLabel">
        {{ $t('enquiryForm.companyNameLabel') }}
      </label>
      <input
        id="company_name"
        v-model="form.companyName"
        type="text"
        class="FormInput">
    </div>
    <div class="FormInputRow">
      <label
        for="requirements"
        class="FormLabel">
        {{ $t('enquiryForm.requirementsLabel') }}
      </label>
      <textarea
        id="requirements"
        v-model="form.requirements"
        rows="4"
        :placeholder="$t('enquiryForm.requirementsPlaceholder')"
        :title="$t('enquiryForm.requirementsPlaceholder')"
        class="FormInput"/>
    </div>
    <div class="FormInputRow">
      <label
        for="promo_code"
        class="FormLabel">
        {{ $t('enquiryForm.promoCodeLabel') }}
      </label>
      <input
        id="promo_code"
        v-model="form.promoCode"
        type="text"
        class="FormInput">
    </div>
    <input
      type="submit"
      class="Button Button--primary"
      :value="$t('enquiryForm.submitLabel')"
      :disabled="submitting">
  </form>
</template>

<script>
import FormDatePicker from './FormDatePicker'
import FormLeadApi from '@/api/FormLeadApi'
import TwoColumns from '@/components/common/TwoColumns'

export default {
  name: 'enquiry-form',
  components: {
    FormDatePicker,
    TwoColumns
  },
  data() {
    return {
      form: null,
      submitting: false
    }
  },
  created() {
    this.resetForm()
  },
  methods: {
    resetForm() {
      this.form = {
        segment: 'catering',
        contactPerson: null,
        date: null,
        email: null,
        phone: null,
        headcount: null,
        companyName: null,
        requirements: null,
        promoCode: null
      }
    },
    onChangeDate(date) {
      this.form.date = date
    },
    async onSubmit() {
      this.submitting = true
      try {
        await FormLeadApi.sendEnquiryForm(this.form)
        this.$modal.show('common-modal', {
          title: this.$t('enquiryForm.successTitle'),
          message: this.$t('enquiryForm.successMessage')
        })
        this.resetForm()
        this.submitting = false
      } catch(e) {
        if (e) {
          this.$modal.show('common-modal', {
            title: this.$t('enquiryForm.errorTitle'),
            message: e
          })
        }
        this.submitting = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
@import "~@/assets/css/form-input";

.EnquiryForm {
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto $space-l;
  background-color: $white;
  padding: $space-l;
  @extend %box-shadow;

  @media only screen and (min-width: 961px) {
    width: 50%;
    padding: $space-l;
  }

  .FormInputRow {
    /deep/ .FormInput {
      margin-bottom: $space-l;
    }
  }
  .FormLabel {
    white-space: nowrap;

    @media (max-width: 640px) {
      white-space: normal;
    }
  }
  .FormInputLabel {
    display: inline-block;
    margin-top: $space-xs;
    margin-right: $space-m;
  }
}
</style>
