<template>
  <div
    class="MenuCard"
    :style="{
      'background-image': menuCardDetails.thumbnail ? `url(${menuCardDetails.thumbnail})` : ''
    }">
    <h3 class="MenuCardTitle">
      {{ menuCardDetails.name }}
    </h3>
    <p class="MenuCardByline">
      {{ menuCardDetails.byline }}
    </p>
    <div
      data-testid="menucard-contact-button"
      class="Button Button--primary ContactButton"
      @click="contact">
      {{ $t('catering.enquirySectionTitle') }}
    </div>
    <div
      v-if="menuCardDetails.enableMultiPdf"
      slot="right"
      class="Button--text Button--text DownloadMenu"
      @click="onDownloadMenu(menuCardDetails)">
      {{ $t('menuCard.downloadLinkTitle') }}
    </div>
    <a
      v-else-if="menuCardDetails.pdf"
      slot="right"
      class="Button--text Button--text DownloadMenu AddOnsMenu"
      :href="menuCardDetails.pdf"
      target="_blank">
      {{ $t('menuCard.downloadLinkTitle') }}
    </a>
  </div>
</template>

<script>
import { SET_IFRAME_MENU } from '../../../actions'

export default {
  name: 'menu-card',
  props: {
    menuCardDetails: {
      type: Object,
      required: true
    }
  },
  methods: {
    onDownloadMenu(menu) {
      this.triggerProductClicked(this.$t('menuCard.downloadLinkTitle'))
      this.$nextTick(() => {
        this.triggerProductViewed(this.$t('menuCard.downloadLinkTitle'))
      })
      this.$store.dispatch(SET_IFRAME_MENU, menu)
    },
    triggerProductClicked(type) {
      this.trackProductClicked(this.menuCardDetails.id, this.menuCardDetails.name, type)
    },
    triggerProductViewed(type) {
      this.trackProductViewed(this.menuCardDetails.id, this.menuCardDetails.name, type)
    },
    contact() {
      this.$emit('contact')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
@import "~@/assets/css/_shared_variables.sass";

.MenuCard {
  position: relative;
  margin-bottom: $space-m;
  text-align: center;
  vertical-align: top;
  min-height: 425px;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertically centers the content */
  align-items: center; /* Horizontally centers the content */
  background-size: cover;
  background-position: center;

  @media (max-width: 640px) {
    margin: 0 auto $space-m;
  }

  .MenuCardTitle,
  .MenuCardByline {
    color:$white;
    max-width: 300px;
    text-align: center;
  }

  .MenuCardTitle {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .MenuCardByline {
    font-size: 1rem;
  }

  .ContactButton {
    height: 44px;
    padding: 0 $space-m;
    line-height: 44px;
    margin-top: $space-m;
    font-weight: bold;
    text-align: center;

    @media #{$small-only} {
      padding: 0 $space-m;
    }
  }

  .DownloadMenu {
    margin-top: $space-m;
    font-size: 0.75rem;
    cursor: pointer;
    color:$white;
  }
}
</style>
