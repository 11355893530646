<template>
  <div class="CustomersImageList">
    <h2>{{ $t('catering.customersSectionTitle') }}</h2>
    <image-list
      :imageList="customers"
      :rows="2"/>
  </div>
</template>

<script>
const ImageList = () => import('@/components/ImageList')

export default {
  name: 'customers-image-list',
  components: {
    ImageList
  },
  data() {
    return {
      customers: [
        require('@/assets/images/customers/airbnb.png'),
        require('@/assets/images/customers/allennovery.png'),
        require('@/assets/images/customers/apple.png'),
        require('@/assets/images/customers/appnexus.png'),
        require('@/assets/images/customers/facebook.png'),
        require('@/assets/images/customers/microsoft.png'),
        require('@/assets/images/customers/uber.png'),
        require('@/assets/images/customers/ubs.png')
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";

@import "~@/assets/css/_shared_variables.sass";

.CustomersImageList {
  max-width: $row-width;

  @media only screen and (min-width: 641px) {
    width: 83.333333%;
    margin: 0 auto;
  }

  h2 {
    @extend %display_large;
    color: $ink;
    text-align: center;
  }

  .ImageList {
    width: 100%;
    margin-top: $space-l;
  }
}
</style>
